import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check mb-5 terms-text text-justify" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.checked]
    ]),
    _createElementVNode("label", {
      class: "form-check-label",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.checked = !_ctx.checked), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}