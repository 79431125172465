
  import { defineComponent } from 'vue';
  import Modal from '@/components/modals/Modal.vue';
	import { Form } from 'vee-validate';
	import FormField from '@/components/forms/FormField.vue';
	import FormCheckInput from '@/components/forms/FormCheckInput.vue';
	import { Plan } from '@/models/PlanModel';
	import { PlanSchema } from '@/schemas/plan.schemas';

	const initialValues = {
		product_key: null,
		monthly_pricing_key: null,	
		full_monthly_pricing_key: null,		
		annual_pricing_key: null,		
		full_annual_pricing_key: null,		
		affiliate_pricing_key: null,		
		has_free_trial: null,		
		free_trial_days: null,		
		name: null,		
		description: null,		
		code: null,		
		monthly_price: null,		
		monthly_discount: null,		
		annual_price: null,		
		annual_discount: null,
	} as unknown as Plan;

	const planDescriptions = [
		'Submit Requested Tenant References',
		'Submit Unlimited Tenant References',
		'Search for Tenant References',
		'View Full History of a Tenant Reference',
		'Request Tenant References',
		'Create Unlimited Number of Users/PMs'
	];

  export default defineComponent({
    components: { Modal, FormField, Form, FormCheckInput },
		props: {
			modelValue: { type: Boolean, default: false },
			loading: { type: Boolean, default: false },
			type: { default: 'store' },
			initialValues: { default: null as unknown as Plan }
		},
		emits: ['update:modelValue', 'onSaved', 'onUpdate'],
		computed: {
			title(){
				return this.type == 'store' ? 'New Plan' : 'Edit Plan'
			},
			btnText(){
				return this.type == 'store' ? 'Save' : 'Update'
			},
			loadingText(){
				return this.type == 'store' ? 'Saving...' : 'Updating...'
			},
			dialog: {
				get() {
					return this.modelValue;
				},
				set(value) {
					this.$emit('update:modelValue', value);
				},
			},
			selectedCode(){
				return this.formInitialValue.code;
			},
			formSchema() {
				return PlanSchema;
			},
			formInitialValue(){
				return this.initialValues ?? initialValues
			},
			descriptions(){
				return planDescriptions;
			}
		},
		data: () => {
			return {
				code: [
					{ label: 'Premium', value: 'premium' },
					{ label: 'Affiliate', value: 'affiliate' },
					{ label: 'Basic', value: 'basic' },
				],
				form: {} as typeof Form,
    		formData: {} as unknown as Plan,
			}
		},
		watch: {
			dialog(value){
				if(!value){
					this.$nextTick(() => {
						this.form.resetForm();
					});
				}
			},
			formInitialValue: {
				handler(value){
					this.setFieldValue('has_free_trial', value.has_free_trial);
					this.setFieldValue('free_trial_days', value.free_trial_days);
					this.setFieldValue('code', value.code);
					this.setFieldValue('description', value.description);
				},
				deep: true
			}
		},
		methods: {
			setFieldValue(field, value) {
				if (!this.form.setFieldValue) return;
				this.form.setFieldValue(field, value);
			},
			handleChangeCode(value){
				this.setFieldValue('code', value)
			},
			async handleSubmitForm(values){
				this.formData = { ...values };
				const { valid, errors } = await (this.$refs.planForm as any).validate();

				if (!valid) {
					console.log('ERRORS', errors);
					return false;
				}

				if(this.type == 'store'){
					this.$emit('onSaved', this.formData);
				}

				if(this.type == 'edit'){
					this.$emit('onUpdate', this.formData);
				}

			},
			validateField(field) {
				if (!this.form.validateField) return;
				this.form.validateField(field);
			},
		},
		mounted() {
			this.form = this.$refs.planForm as typeof Form;
		}
  });
