
  import { defineComponent } from 'vue';
  import Card from '@/views/new-design/components/Card.vue';
  import DataTable from '@/components/datatables/KTDataTable.vue';
  import useBreakpoints from 'vue-next-breakpoints';
	import Navigator from '@/views/new-design/layout/Navigator.vue';
	import { Plan } from '@/models/PlanModel';
	import { money } from '@/utils/currency';
	import AddPlanModal from '@/views/new-design/pages/Settings/Plans/AddPlanModal.vue';
	import toasts from '@/utils/toasts';
	import { Actions as PlanActions, Getters as PlanGetters } from '@/store/enums/PlanEnums';
	import { mapActions, mapGetters } from 'vuex';

  export default defineComponent({
    components: { Card, DataTable, Navigator, AddPlanModal },
    data: () => {
      return {
        table: {
          total: 0,
          items: [] as Array<Plan>,
        },
				add_plan: false,
				loading: false,
				saving: false,
				plan: null as unknown as Plan,
				formType: 'store'
      };
    },
		watch: {
			add_plan(value){
				if(!value){
					this.formType = 'store';
					this.plan = null as unknown as Plan;
				}
			}
		},
    computed: {
			...mapGetters({
				getPlans: PlanGetters.GET_PLANS
			}),
      breakpoint() {
        return useBreakpoints({
          mobile: 800,
          desktop: [801],
        });
      },
      isMobile() {
        return (this.breakpoint as any)?.mobile?.matches;
      },
      tableHeaders() {
        return [
          { name: 'Name', key: 'name' },
          { name: 'Monthly Price', key: 'formatted_monthly_price' },
          { name: 'Annual Price', key: 'formatted_annual_price' },
          { name: 'Has Free Trial', key: 'formatted_has_free_trial', hidden: this.isMobile },
          { name: '', key: 'action', hidden: this.isMobile, align: 'end' },
        ];
      },
			formattedPlanLists(){
				return this.getPlans.map(item => {
					item.formatted_monthly_price = money.format(item.monthly_price);
					item.formatted_annual_price = money.format(item.annual_price);
					item.formatted_has_free_trial = item.has_free_trial ?? 'No';
					item.has_free_trial = item.has_free_trial == 'yes' ? true : false;
					return item;
				});
			}
    },
    methods: {
			...mapActions({
				fetchPlans: PlanActions.FETCH_PLANS,
				storePlan: PlanActions.STORE_PLAN,
				updatePlan: PlanActions.STORE_PLAN,
				deletePlan: PlanActions.DELETE_PLAN
			}),
      async loadPlans(){
				this.loading = true;
				await this.fetchPlans({ returnAll: true }).then(res => {
					this.table.items = this.formattedPlanLists;
					this.table.total = this.formattedPlanLists.length
				}).finally(() => {
					this.loading = false;
				});
			},
			async handleStorePlan(payload){
				this.saving = true;
				await this.storePlan(payload).then(res => {
					toasts.success('Plan has been saved.');
					this.loadPlans();
				}).catch(() => {
					toasts.error({}, 'Something went wrong. Please try again.');
				}).finally(() => {
					this.saving = false;
				});
			},
			async handleUpdatePlan(payload){
				console.log(payload);
				this.saving = true;
				await this.storePlan({ id: payload.id, data: payload }).then(res => {
					toasts.success('Plan has been updated.');
					this.loadPlans();
				}).catch(() => {
					toasts.error({}, 'Something went wrong. Please try again.');
				}).finally(() => {
					this.saving = false;
				});
			},
			editPlan(plan){
				this.formType = 'edit';
				this.plan = plan;
				this.add_plan = true;
			},
			confirmDeletePlan(plan){
				toasts.confirm('Are you sure you want to delete the plan?', (result) => {
					if (result.isConfirmed) {
						this.handleDeletePlan(plan);
					}
				});
			},
			async handleDeletePlan(plan){
				await this.deletePlan(plan.id).then(() => {
					toasts.success('Plan has been deleted.');
					this.loadPlans();
				}).catch(() => {
					toasts.error({}, 'Something went wrong. Please try again.');
				});
			}
    },
		mounted(){
			this.loadPlans();
		}
  });
